import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';
import avatar from './imagenes/hombre.png';

//import CamaraWeb from "./CamaraWeb";

class App extends Component 
{
	constructor(props)
	{
		super(props)
		this.state = {
			registro :
			{
				id : null,
				num_control : '',
				apellido_paterno : '',
				apellido_materno : '',
				nombre : '',
				semestre : '',
				grupo : '',
				turno : '',
				especialidad : '',
				inscrito : '',
				fotografia : '',
				tamano : '',
				validada : '',
				seleccionada : '',
				impresa : '',
			},
			dato : "",
			alumnos : [],
			foto : '',
			conectado : false,
			imagen : '',
			seleccionado :null,
			archivo : null,
			cantidad : 0,
			modoRFID : 1,
			campocodigo : 0
		}
		//this.fotoDeLaCamara = this.fotoDeLaCamara.bind(this);
	}

	componentDidMount()
	{
		var { foto } = this.state;
		foto=avatar;
		this.setState({ foto : foto });
	}

	submit=(evt)=>{
		evt.preventDefault();
	}

	handleInputChange=(event)=>
	{
		const value = event.target.value;
		var { dato } = this.state;

		dato = value;

		this.setState({ dato : dato });
	}

	handleInputChange2=(event)=>
	{
		const value = event.target.value;
		var { codigo } = this.state;

		codigo = value;

		this.setState({ codigo : codigo });
	}

	buscarAlumnos=()=> 
	{
		var { registro, alumnos, foto, dato, seleccionado, cantidad } = this.state;
		let temp = this;
		var a;

		request.get('/BuscarIndefinido/'+dato)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					
		 			alumnos = response.data;
		 			console.log(response.data);
		 			cantidad=Object.keys(alumnos).length;
		 			for(a=0;a<cantidad;a++)
		 			{
		 				alumnos[a].seleccionada=0;
		 			}
		 			if(cantidad === 1)
		 			{
						registro.num_control = alumnos[0].num_control;
						registro.apellido_paterno = alumnos[0].apellido_paterno;
						registro.apellido_materno = alumnos[0].apellido_materno;
						registro.nombre = alumnos[0].nombre;
						registro.semestre = alumnos[0].semestre;
						registro.grupo = alumnos[0].grupo;
						registro.turno = alumnos[0].turno;
						registro.especialidad = alumnos[0].especialidad;
						registro.fotografia = alumnos[0].fotografia;
						registro.tamano = alumnos[0].tamano;
						registro.inscrito = alumnos[0].inscrito;
						seleccionado = 0;
		 			}
		 			else
		 			{
		 				registro.num_control = "";
						registro.apellido_paterno = "";
						registro.apellido_materno = "";
						registro.nombre = "";
						registro.semestre = "";
						registro.grupo = "";
						registro.turno = "";
						registro.especialidad = "";
						registro.fotografia = "";
						registro.tamano = "";
						registro.inscrito = "";
						seleccionado=null;
		 			}
		 			
		 			temp.setState({ alumnos : alumnos, seleccionado : seleccionado, cantidad : cantidad, foto:foto });
		 		}
		 		else
		 		{
		 			console.log("3");
		 			registro.credencial="";
	 				Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No Registrado',
						toast: true,
						showConfirmButton: false,
						timer: 1500
					});
		 			temp.setState({ registro : registro });
		 		}
		 	}
		})
		.catch(error => {} );
	}

	porImprimir=()=>
	{
		var { registro, alumnos, foto, dato, seleccionado, cantidad } = this.state;
		let temp = this;

		request.get('/BuscarCredencialesPendientes')
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
		 			alumnos = response.data;
	 				registro.num_control = "";
					registro.apellido_paterno = "";
					registro.apellido_materno = "";
					registro.nombre = "";
					registro.semestre = "";
					registro.grupo = "";
					registro.turno = "";
					registro.especialidad = "";
					registro.fotografia = "";
					registro.tamano = "";
					registro.inscrito = "";
					seleccionado=null;
		 			temp.setState({ alumnos : alumnos, seleccionado : seleccionado, cantidad : cantidad, foto:foto });
		 		}
		 		else
		 		{
		 			registro.credencial="";
	 				Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No Registrado',
						toast: true,
						showConfirmButton: false,
						timer: 1500
					});
		 			temp.setState({ registro : registro });
		 		}
		 	}
		})
		.catch(error => {} );
	}
	ajustarFoto=(event,s)=>
	{
		var { alumnos, seleccionado } = this.state;
		alumnos[seleccionado].tamano = s;
		this.imprimirCredencial(event,seleccionado,s);
		this.setState({ alumnos : alumnos });
	}

	reporte=()=>
	{
		request_file()
		.get('/exportar')
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]),);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download',`Reporte.xlsx`,);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);

		})
		.catch(error => {
			console.log(error);
		});
	}

	imprimirSeleccionadas=()=>
	{
		var { alumnos  } = this.state;
		var a=0;
		let cola=[];

		for(a=0;a<alumnos.length;a++)
		{
			if(alumnos[a].seleccionada === 1)
			{
				cola.push(alumnos[a].num_control);
				alumnos[a].seleccionada=0;
			}
		}

		request_file()
		.post('/ImprimirCredenciales',cola)
		.then(response => {

			// Mostrar en PDF
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL,"visor");

			// Descarga Directa
			// const url = window.URL.createObjectURL(new Blob([response.data]),);
			// const link = document.createElement('a');
			// link.href = url;
			// link.setAttribute('download',`Credenciales 2024.pdf`,);
			// document.body.appendChild(link);
			// link.click();
			// link.parentNode.removeChild(link);

		})
		.catch(error => {
			console.log(error);
		});

		this.setState({ alumnos : alumnos, cola : cola });
	}

	imprimirCredencial=(event,i,s)=>
	{
		event.preventDefault();
		var { alumnos } = this.state;

		request_file()
		.post(`/ImprimirCredencial?num_control=${alumnos[i].num_control}&tam=${s}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL,"visor");
		})
		.catch(error => {
			console.log(error);
		});
	}

	seleccionarParaImprimir=(event,i)=>
	{
		var { alumnos } = this.state;
		
		if(parseInt(alumnos[i].seleccionada,10)===0)
			alumnos[i].seleccionada=1;
		else
			alumnos[i].seleccionada=0;

		this.setState({ alumnos : alumnos });
	}

	seleccionarAlumno=(event,i)=>
	{
		event.preventDefault();
		var { registro, seleccionado, alumnos, foto } = this.state;
		let temp=this;

		seleccionado = i;
		registro.num_control = alumnos[i].num_control;
		registro.apellido_paterno = alumnos[i].apellido_paterno;
		registro.apellido_materno = alumnos[i].apellido_materno;
		registro.nombre = alumnos[i].nombre;
		registro.semestre = alumnos[i].semestre;
		registro.grupo = alumnos[i].grupo;
		registro.turno = alumnos[i].turno;
		registro.especialidad = alumnos[i].especialidad;
		registro.fotografia = alumnos[i].fotografia;
		registro.tamano = alumnos[i].tamano;
		registro.inscrito =alumnos[i].inscrito;

		if(parseInt(registro.fotografia,10)===0)
		{
			foto=avatar;
		}
		else
		{
			request_file().post(`/MostrarImagen?codigo=${registro.num_control}`)
			.then(response =>
			{
				if(response.data)  
				{
					const file = new Blob([response.data], {type: 'image/jpeg' });
					if(file.size !== 0)
					{
						const fileURL = URL.createObjectURL(file);
						foto=fileURL;
						temp.setState({ foto : foto });
					}
				}
			})
			.catch(error => { });

			this.setState({ foto : foto });
		}

		this.setState({ registro : registro, seleccionado : seleccionado, foto : foto });
		if(registro.fotografia===1)
		{
			this.imprimirCredencial(event,i,0);
		}
		else
		{
			window.open('about:blank',"visor");
		}
	}

	fotoDeLaCamara(data)
	{
		this.setState ({ foto : data });
	}

	guardarFoto=(event)=>
	{
		var { seleccionado, foto, alumnos } = this.state;
		let temp = this;

		const imageData = new FormData();
		imageData.append('num_control',alumnos[seleccionado].num_control);
		imageData.append('foto',foto);

		request.post('/CargarImagenCredencial',imageData)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					Swal.fire({
						position: 'center-end',
						icon: 'success',
						title: 'Imagen guardada',
						toast: 'true',
						showConfirmButton: false,
						timer: 2500
					});
					alumnos[seleccionado].fotografia=1;			
					temp.setState({ alumnos : alumnos });
				}
				else
				{
					Swal.fire({
						position: 'center-end',
						icon: 'error',
						title: 'Hay un problema con la imagen',
						showConfirmButton: false,
						timer: 2500
					});
				}
			}
		})
		.catch(error =>
		{
			Swal.fire({
				position: 'top-end',
				icon: 'error',
				title: 'No se guardo la información.',
				showConfirmButton: false,
				timer: 1500
			});
		});

		this.setState({ seleccionado : seleccionado });
	}

	validarCredencial=()=>
	{
		var { alumnos, seleccionado } = this.state;
		let temp = this;

		request.get('/ValidarCredencial/'+alumnos[seleccionado].num_control)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
		 			alumnos[seleccionado].validada = response.data;
		 			temp.setState({ alumnos : alumnos });
		 			Swal.fire({
					  position: "top-end",
					  icon: "success",
					  title: "",
					  width: "100px",
					  showConfirmButton: false,
					  timer: 1500,
					  toast: true
					});
		 		}
		 	}
		})
		.catch(error => {} );
		this.setState({ alumnos : alumnos });
	}

	modoRfid=()=>
	{

		var { modoRFID, dato, alumnos, seleccionado, campocodigo } = this.state;
		let temp = this;

		if(parseInt(modoRFID,10) === 1)
			modoRFID = 2;
		else
			modoRFID = 1;

		dato="";
		alumnos=[];
		seleccionado=null;
		campocodigo=0;

		this.setState({ modoRFID : modoRFID, dato : dato, alumnos : alumnos, seleccionado : seleccionado, campocodigo : campocodigo });
	}

	buscarAlumnoParaRFID=()=>
	{
		var { registro, dato, seleccionado, campocodigo } = this.state;
		let temp = this;
		var a;

		request.get('/BuscarNumControlParaRFID/'+dato)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
		 			registro = response.data;
					seleccionado = 0;
					campocodigo = 1;
		 			temp.setState({ registro : registro, seleccionado : seleccionado, dato : dato, campocodigo : campocodigo });
		 		}
		 		else
		 		{
		 			registro.credencial="";
		 			registro.id=null;
	 				Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No Registrado',
						toast: true,
						showConfirmButton: false,
						timer: 1500
					});
		 			temp.setState({ registro : registro, dato : dato });
		 		}
		 	}
		})
		.catch(error => {} );
				dato="";
		this.setState({ registro : registro, seleccionado : seleccionado, dato : dato, campocodigo : campocodigo  });
				
	}

	enviarRFID=()=>
	{
		var { registro, dato, seleccionado, codigo, campocodigo } = this.state;
		let temp = this;
	

		const datos = new FormData();
		datos.append('num_control',registro.num_control);
		datos.append('codigo',codigo);
			
		request.post('/LigarRFID',datos)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					Swal.fire({
						position: 'center-end',
						icon: 'success',
						toast: true,
						width: '100px',
						position: 'center',
						showConfirmButton: false,
						timer: 1500
					});
			
					temp.setState({ registro : registro });
				}
				else
				{
					Swal.fire({
						position: 'center-end',
						icon: 'error',
						toast: true,
						width: '100px',
						position: 'center',
						showConfirmButton: false,
						timer: 2500
					});
				
					temp.setState({ campocodigo : campocodigo });
				}
			}
		})
		.catch(error =>
		{
			Swal.fire({
				position: 'center-end',
				icon: 'error',
				toast: true,
				width: '100px',
				position: 'center',
				showConfirmButton: false,
				timer: 2500
			});
		});
			campocodigo = 0;
		codigo="";
		this.setState({ registro : registro, campocodigo : campocodigo, codigo : codigo });
	}

	render()
	{
		let { conectado, alumnos, foto, webcamRef, seleccionado, registro, modoRFID, dato, campocodigo, codigo } = this.state;

		return (
			<div className="body">
				<div className="main">
					<Row className="pl-1 pr-1">
						<Col className="col-md-12 col-lg-8">
							<Form onSubmit={this.submit}>
								<Row>
									<Col>
									{
										parseInt(modoRFID)===1 ?
											<div className="pl-2 pr-2 input-group mb-1 loginForm">
												<span className="input-group-text" id="basic-addon1"> BUSCAR... </span>
												<input type="text" autoFocus autoComplete="off" className="form-control" id="dato" name="dato" placeholder="" value={dato} aria-label="dato" aria-describedby="basic-addon1" onChange={this.handleInputChange}/>
												<button className="input-group-text" onClick={this.buscarAlumnos}> <span className="oi" title="Buscar" data-glyph="magnifying-glass" aria-hidden="true"></span> </button>
												<button className="input-group-text" onClick={this.porImprimir}> NO IMPRESAS </button>
												<button className="input-group-text az" onClick={this.modoRfid}> <span className="oi" title="Buscar" data-glyph="audio-spectrum" aria-hidden="true"></span> &nbsp; LIGAR RFID </button>
												<button className="input-group-text" onClick={this.reporte}> <span className="oi" title="Reporte" data-glyph="data-transfer-download" aria-hidden="true"></span> </button>
											</div>
										:
											parseInt(campocodigo)===0 ?
												<div className="pl-2 pr-2 input-group mb-1 loginForm">
													<span className="input-group-text bg-success text-white" id="basic-addon1"> BUSCAR &nbsp; <span className="oi" title="Salir" data-glyph="audio-spectrum" aria-hidden="true"></span> </span>
													<input type="text" autoFocus autoComplete="off" className="form-control" id="dato" name="dato" placeholder="" value={dato} aria-label="dato" aria-describedby="basic-addon1" onChange={this.handleInputChange}/>
													<button className="input-group-text" onClick={this.buscarAlumnoParaRFID}> <span className="oi" title="Buscar" data-glyph="magnifying-glass" aria-hidden="true"></span> </button>
													<button className="btn btn-danger" onClick={this.modoRfid}> <span className="oi" title="Salir" data-glyph="audio-spectrum" aria-hidden="true"></span> &nbsp; SALIR </button>
													<button className="input-group-text" onClick={this.reporte}> <span className="oi" title="Reporte" data-glyph="data-transfer-download" aria-hidden="true"></span> </button>
												</div>
											:
													
												<div className="pl-2 pr-2 input-group mb-1 loginForm">
													<span className="input-group-text bg-danger text-white" id="basic-addon1"> LEER TARJETA &nbsp; <span className="oi" title="Salir" data-glyph="audio-spectrum" aria-hidden="true"></span> </span>
													<input type="text" autoFocus autoComplete="off" className="form-control" id="codigo" name="codigo" placeholder="" value={codigo} aria-label="codigo" aria-describedby="basic-addon1" onChange={this.handleInputChange2}/>
													<button className="input-group-text" onClick={this.enviarRFID}> <span className="oi" title="Buscar" data-glyph="magnifying-glass" aria-hidden="true"></span> </button>
													<button className="btn btn-danger" onClick={this.modoRfid}> <span className="oi" title="Salir" data-glyph="audio-spectrum" aria-hidden="true"></span> &nbsp; SALIR </button>
													<button className="input-group-text" onClick={this.reporte}> <span className="oi" title="Reporte" data-glyph="data-transfer-download" aria-hidden="true"></span> </button>
												</div>
									}
									</Col>
								</Row>
							</Form>
		{/* CUADRO DE FOTO */}					
							<Row>
								<Col>
									<div className="pl-2 pr-2 input-group mb-1 loginForm">
										<table>
											<tbody>
												<tr className="f ml-2">
													{ /*
														parseInt(modoRFID,10)===1 ?
															<td className="f">
																<CamaraWeb mode={"environment"} fotoDeLaCamara={this.fotoDeLaCamara}/>
															</td>
														:
															<td>
															</td>
															*/
													}
													<td className="datos">
														<span className="ne">No. Control:</span>
														<br/>
														{
															alumnos.length>0? 
																<span className="ne b pl-3"> {registro.num_control} </span>
															:
																registro.id!==null? 
																	<span className="ne b pl-3"> {registro.num_control} </span>
																:
																	""
														}
														<br/>
														<span className="ne"> Apellido Paterno:</span>
														<br/>
														{
															alumnos.length>0? 
																<span className="ne b pl-3"> {registro.apellido_paterno} </span>
															:
																registro.id!==null?
																	<span className="ne b pl-3"> {registro.apellido_paterno} </span>
																:
																	""
														}
														<br/>
														<span className="ne"> Apellido Materno:</span>
														<br/>
														{
															alumnos.length>0? 
																<span className="ne b pl-3"> {registro.apellido_materno} </span>
															:
																registro.id!==null?
																	<span className="ne b pl-3"> {registro.apellido_materno} </span>
																:
																	""
														}
														<br/>
														<span className="ne"> Nombre(s): </span>
														<br/>
														{
															alumnos.length>0? 
																<span className="ne b pl-3"> {registro.nombre} </span>
															:
																registro.id!==null?
																	<span className="ne b pl-3"> {registro.nombre} </span>
																:
																	""
														}
														<br/>
														<span className="ne"> Grupo: </span>
														{
															alumnos.length>0? 
																<span className="ne b pl-1"> {registro.semestre}{registro.grupo}{registro.turno}{registro.especialidad} </span>
															:
																registro.id!==null?
																	<span className="ne b pl-1"> {registro.semestre}{registro.grupo}{registro.turno}{registro.especialidad} </span>
																:
																	""
														}
														<br/>
													</td>
													<td className="der">
														{
															parseInt(modoRFID,10)===1 ?
																<img className="cuadroFoto img-thumbnail" align="middle" src={foto} alt="..."/> 
															:
																""
														}
														<br/>
														{
															parseInt(modoRFID,10)===1 ?
																<button className="btn btn-primary" id="foto" name="foto" onClick={this.guardarFoto}> Guardar fotografia </button>
															:
																""
														}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
								
		{/* LISTA DE REPORTE */}
							<Form>
								<Row>
									<Col>
										<div className="pl-2 pr-2 loginForm maxh lista">
											<table className="table table-responsive-sm table-striped table-bordered table-sm">
												<tbody>
												{
													alumnos.map((item, i) => 
														<tr key={i}  >
															<td className="csm cen ne"> { i+1 } </td>
															{
																//parseInt(item.tipo)===1 ?
																//	<td className="cxs cen"> <span className="oi gr" title="Inscrito" data-glyph="task" aria-hidden="true"></span> </td>
																//:
																//	<td className="cxs cen"> <span className="oi ro" data-glyph="task" aria-hidden="true"></span> </td>
															}
															{
																parseInt(item.fotografia)===0 ?
																	<td className="cxs cen"> <span className="oi ro" title="Falta fotografia" data-glyph="camera-slr" aria-hidden="true"></span> </td>
																:
																	<td className="cxs cen"> <span className="oi ve" title="Con Fotografia" data-glyph="camera-slr" aria-hidden="true"></span> </td>
															}
															{
																parseInt(item.impresa)===0 ?
																	<td className="cxs cen"> <span className="oi ro" title="Sin imprimir" data-glyph="print" aria-hidden="true"></span> </td>
																:
																	<td className="cxs cen"> <span className="oi ve" title="Impreso" data-glyph="print" aria-hidden="true"></span> </td>
															}
															{
																parseInt(item.validada)===1 ?
																	<td className="cxs cen"> <span className="oi ro" title="Falta validar" data-glyph="thumb-down" aria-hidden="true"></span> </td>
																:
																	<td className="cxs cen"> <span className="oi ve" title="Validada" data-glyph="thumb-up" aria-hidden="true"></span> </td>
															}


															<td className="cmd cen ne"> {item.semestre}{item.grupo}{item.turno}{item.especialidad} </td>
															<td className="cx ne" onClick={(e)=>this.seleccionarAlumno(e,i)} >
																{item.apellido_paterno}  {item.apellido_materno}, {item.nombre}
																<br/>
																{item.num_control}
															</td>
															{
																//parseInt(item.fotografia)===0 ?
																//	<td className="clg cen"> <button className="btn btn-secondary btn-sm" type="button" id="button-addon2" > GENERAR </button> </td>
																//:
																//	<td className="clg cen"> <button className="btn btn-warning btn-sm b" type="button" id="button-addon2" onClick={(e)=>this.imprimirCredencial(e,i,0)} > GENERAR </button> </td>

															}
															{
																parseInt(item.validada)===2 ?
																	parseInt(item.seleccionada) === 1 ?
																		<td className="cxs cen"> 
																			<input className="chk" title="Imprimir" type="checkbox" value="" id="seleccionada" name="seleccionada" checked onChange={(e)=>this.seleccionarParaImprimir(e,i)} />
																		</td>
																	:
																		<td className="cxs cen"> 
																			<input className="chk" title="Seleccionar" type="checkbox" value="" id="seleccionada" name="seleccionada" onChange={(e)=>this.seleccionarParaImprimir(e,i)} />
																		</td>
																:
																	<td className="cxs cen"> 
																		<input className="chk" type="checkbox" value="" id="seleccionada" name="seleccionada" disabled />
																	</td>
															}
														</tr>
													)
												}
												</tbody>
											</table>				
										</div>
									</Col>
								</Row>
							</Form>
						</Col>
						{
							seleccionado !== null ?
								parseInt(modoRFID,10)===1 ?
									<Col className="col-md-12 col-lg-4">
										<div className="pl-2 pr-2 input-group mb-1 loginForm credencial">
											{
												parseInt(alumnos[seleccionado].tamano) === 1 ?
													<button className="btn btn-primary btn-sm b" type="button" id="button-addon2" onClick={(e)=>this.ajustarFoto(e,2)} >
														<span className="oi bl" data-glyph="chevron-left" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="person" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="chevron-right" aria-hidden="true"></span>
													</button>
												:
													<button className="btn btn-secondary disabled btn-sm b" type="button" id="button-addon2" >
														<span className="oi bl" data-glyph="chevron-left" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="person" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="chevron-right" aria-hidden="true"></span>
													</button>
											}
											{
												parseInt(alumnos[seleccionado].tamano) === 2 ?
													<button className="btn btn-primary btn-sm b" type="button" id="button-addon2" onClick={(e)=>this.ajustarFoto(e,1)} >
														<span className="oi bl" data-glyph="chevron-right" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="person" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="chevron-left" aria-hidden="true"></span>
													</button>
												:
													<button className="btn btn-secondary disabled btn-sm b" type="button" id="button-addon2" >
														<span className="oi bl" data-glyph="chevron-right" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="person" aria-hidden="true"></span>
														<span className="oi bl" data-glyph="chevron-left" aria-hidden="true"></span>
													</button>
											}

											<button className="btn btn-success btn-sm b" type="button" id="button-addon2" onClick={(e)=>this.validarCredencial()} > VALIDAR </button>
											<button className="btn btn-warning btn-sm b pull-right" type="button" id="button-addon2" onClick={(e)=>this.imprimirSeleccionadas()} > IMPRIMIR </button>
											
											<iframe title="visor" id="visor" name="visor" src='about:blank'></iframe>
										</div>
										<div>
										</div>
									</Col>
								:
									""
							:
								""
						}
					</Row>
				</div>
			</div>
		);
	}
}

export default App;